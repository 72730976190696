.mat-tab-body-wrapper {
  padding: 1rem 0;
}

.mat-tab-labels {
  .mat-tab-label:nth-child(1) {
    background-image: linear-gradient(to right, #d3d3d352, #d9d9d9);
  }
}

.mat-tab-body-content {
  padding-top: 0.5rem;
  overflow: hidden !important;
}

.pagination {
  margin-top: 1.5em;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  align-items: center;
  justify-items: end;

  &__main {
    grid-column: 4 / span 3;

    .mat-paginator-range-label {
      margin: 0;
      min-width: 6rem;
    }
  }

  &__form {
    max-width: 9.5rem;

    &__per-page {
      align-self: center;

      .mat-form-field-label {
        margin-top: 0.1rem;
        top: 1.5rem !important;
      }

      .mat-form-field-flex {
        max-width: 9.5rem;
      }
    }
  }
}

.reduced-select.mat-form-field-appearance-outline {
  .mat-form-field-flex {
    align-self: center;
    margin-top: 0.8rem;
    height: 2.95rem;
  }

  .mat-select-arrow-wrapper {
    transform: none;
  }

  .mat-select-trigger {
    height: 1.27rem;
  }

  .mat-form-field-label {
    margin-top: -0.25em;
    top: 1.5rem !important;
  }

  .mat-form-field-label-wrapper {
    overflow: unset;
  }

  .mat-form-field-prefix {
    top: -0.1875rem;
    padding-right: 0 !important;
  }

  .mat-form-field-infix {
    padding: 0;
  }

  select {
    vertical-align: middle;
    font-weight: 300;
    font-size: 0.875rem;
    line-height: 1.21;
    margin: 0.1rem 0;
  }
}

.mat-form-field.mat-form-field-appearance-outline .mat-form-field-suffix {
  top: 0.25rem;
}

.mat-dialog-container {
  border-radius: 1.5rem !important;
}
